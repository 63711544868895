/* * { */
/* background-color: #f0f2f5; */
/* box-sizing: border-box; */
/* } */
:root {
  --bs-blue: #63b3ed;
  --bs-indigo: #596cff;
  --bs-purple: #6f42c1;
  --bs-pink: #f74e89;
  --bs-red: #f56565;
  --bs-orange: #fd7e14;
  --bs-yellow: #fbd38d;
  --bs-green: #81e6d9;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #f0f2f5;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #e91e63;
  --bs-secondary: #7b809a;
  --bs-success: #4caf50;
  --bs-info: #1a73e8;
  --bs-warning: #fb8c00;
  --bs-danger: #f44335;
  --bs-light: #f0f2f5;
  --bs-dark: #344767;
  --bs-white: #fff;
  --bs-primary-rgb: 233, 30, 99;
  --bs-secondary-rgb: , 128, 154;
  --bs-success-rgb: 76, 175, 80;
  --bs-info-rgb: 26, 115, 232;
  --bs-warning-rgb: 251, 140, 0;
  --bs-danger-rgb: 244, 67, 53;
  --bs-light-rgb: 240, 242, 245;
  --bs-dark-rgb: 52, 71, 103;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: , 128, 154;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: "Roboto", Helvetica, Arial, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #7b809a;
  --bs-body-bg: #f0f2f5;
  --bs-border-color: #dee2e6;
  border: 1px solid rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.125rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 0.75rem;
  --bs-border-radius-2xl: 1rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #e91e63;
  --bs-link-hover-color: #e91e63;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fcf8e3;
}

::-webkit-scrollbar {
  height: 1px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  background: #181821;
}

.btn-secondari {
  background-color: var(--bs-secondary);
  color: #fff !important;
  transition: all 0.25s ease-out !important;
}

.btn-secondari:hover {
  background-color: var(--bs-secondary) !important;
  color: #ffff !important;
}

.btn-secondari:focus {
  position: relative !important;
  bottom: -3px !important;
  box-shadow: -6px 14px 12px 2px rgba(90, 90, 90, 0.12) !important;
}

.btn-inf {
  background-color: var(--bs-info);
  color: #fff !important;
  transition: all 0.25s ease-out !important;
}

.btn-inf:hover {
  background-color: var(--bs-info) !important;
  color: #ffff !important;
}

.btn-inf:focus {
  position: relative !important;
  bottom: -3px !important;
  box-shadow: -6px 14px 12px 2px rgba(90, 90, 90, 0.12) !important;
}

/* .btn-gradient { */
/* background-color: var(--bs-primary); */
/* color: #fff; */
/* transition: all 0.25s ease-out; */
/* } */
/* .btn-gradient:hover { */
/* background-color: var(--bs-primary) !important; */
/* color: #ffff; */
/* } */
/* .btn-gradient:focus { */
/* position: relative; */
/* bottom: -3px; */
/* box-shadow: -6px 14px 12px 2px rgba(90, 90, 90, 0.12); */
/* } */
.text-pink {
  color: var(--bs-primary);
}

/* Write your custom CSS here */
.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

::-webkit-scrollbar {
  height: 1px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 8px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  background: #181821;
}

::-webkit-scrollbar-thumb {
  background: #262635;
}

.page-sidebar .accordion-menu::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.collapse::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 2px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
.g-sidenav-hidden .sub-menu{
  margin: 0px;
}
.g-sidenav-pinned .sub-menu{
  margin-left: 20px;
}
.sub-menu .nav-item a{
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.sub-menu .nav-item a svg{
  width: 22px !important; 
}
.sub-menu .nav-item .nav-link.active{
  font-size: 15px !important;
    color: #fff;
    border-right-width: 0;
    border-bottom-width: 0;
    background-color: hsla(0, 0%, 78%, 0.2);
}

.followDiv::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 3px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
.btn-edit{
  background-image: linear-gradient(195deg, #ff4d67, #ec6f81) !important;
}
.image {
  opacity: 1;
  display: block;
  width: 38%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container:hover .image {
  opacity: 0.6;
  cursor: grab;
}

.container:hover .middle {
  opacity: 1;
}

.page-sidebar .accordion-menu > li > span {
  display: block;
  color: #9a9cab;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  line-height: 45px;
  padding: 0 15px;
  text-decoration: none;
  cursor: pointer;
}

.page-sidebar .accordion-menu > li > span > svg {
  width: 21px;
  height: 21px;
  line-height: 40px;
  text-align: center;
  vertical-align: text-top;
  color: #9a9cab;
  margin-right: 15px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.sidenav__icon {
  margin-right: 15px;
}

.card-overflow {
  overflow-x: scroll;
}

.card-overflow::-webkit-scrollbar {
  height: 1px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 3px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.card-overflow::-webkit-scrollbar-track {
  background: #181821;
}

.card-overflow::-webkit-scrollbar-thumb {
  background: #1f1f2b;
}

.back__style {
  width: "100%";
  height: 100vh;
  display: flex !important;
  align-items: center !important;
  background-size: "cover";
  background-attachment: "fixed";
  margin: 0;
  padding: 0;
  /* background-image: url("../images/login.png"); */
}

.icon_margin {
  margin-left: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 58px;
  height: 32px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #86c1ed;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ada6f2;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ada6f2;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* .searchBar:focus { */
/* background-color: #3e3e6600; */
/* box-shadow: none; */
/* } */
/*  */
.MuiTypography-colorInherit {
  color: #91a3b0 !important;
}

.MuiSelect-select {
  color: #91a3b0 !important;
}

.MuiSelect-icon {
  color: #91a3b0 !important;
}

.MuiIconButton-root.Mui-disabled {
  color: #9a9cab !important;
}

.MuiIconButton-root {
  color: #86c1ed !important;
}

.float__right {
  float: right;
}

.MuiPaper-root {
  background-color: #fafafa !important;
}

.text-gray {
  color: #91a3b0;
}

.text-red {
  color: #de1738;
  font-family: Circular-Loom;
  font-size: 0.87rem;
}

.collapse-margin {
  margin-left: -7px;
  /* overflow-x: scroll; */
}

.pointer-cursor {
  cursor: pointer;
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: aliceblue;
  color: #fff;
}

.post-padding {
  padding: 6px 15px !important;
  margin-bottom: 0;
}

.commentImg {
  margin-top: 13px;
}

.shadow {
  box-shadow: rgb(105 103 103 / 50%) 0px 5px 15px 0px !important;
}

.comment-delete {
  margin-left: 10px;
}

.form-check-input:focus,
.form-control:focus {
  box-shadow: none;
}

.rows > * {
  max-height: 500px;
}

.pie > * {
  max-height: 350px;
}

.swal-title {
  color: rgba(0, 0, 0, 0.65) !important;
  font-weight: 500 !important;
}

.swal-text {
  color: rgba(0, 0, 0, 0.64) !important;
}

.swal-modal {
  background-color: #e0e0e0 !important;
}

.swal-icon--success__hide-corners,
.swal-icon--success:after,
.swal-icon--success:before {
  background-color: transparent;
}

.MuiSelect-select:not([multiple]) option,
.MuiSelect-select:not([multiple]) optgroup {
  background-color: #161622 !important;
}

.chip,
.highlightOption,
.multiSelectContainer li:hover {
  background: #e8538f !important;
}

.page-sidebar .accordion-menu {
  overflow-x: scroll !important;
}

.danger {
  background-color: #f5365c;
}

.success {
  background-color: #146356;
}

.stats-card .stats-icon.change-pink {
  color: #fff;
  background-color: #fc9494;
  box-shadow: 0 3px 18px -8px #fc9494;
}

.border-right-radius {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem;
}

.pagination {
  /* float: right; */
}

.page-link {
  border-radius: 50% !important;
  padding: 5px 13px;
}

.page-item {
  padding: 5px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.page-link:focus {
  box-shadow: none;
}

.dropdown {
  max-height: 30px;
  margin-top: 9px;
  padding: 3px 5px;
  font-size: 12px;
  max-width: 80px;
}

#datePicker {
  position: absolute;
  z-index: 1;
}

.table {
  position: relative;
  text-align: center !important;
}

.rdrDefinedRangesWrapper {
  background: #181821 !important;
  color: #fff;
  border: solid 1px #9a9cab !important;
}
.rdrMonthName {
  color: #fff !important;
}

.rdrStaticRange {
  background: #181821 !important;
  color: #9a9cab;
  border-bottom: 1px solid #9a9cab !important;
}

.rdrCalendarWrapper {
  color: #181821;
  font-size: 12px;
  border: 1px solid #9a9cab;
}

.rdrMonthPicker,
.rdrYearPicker {
  border: 1px solid #9a9cab;
  border-radius: 8px;
}

.rdrCalendarWrapper,
.rdrInputRangeInput {
  background: #181821 !important;
}

.rdrMonthAndYearPickers select,
.rdrDayPassive .rdrDayNumber span {
  color: #fff !important;
}

.rdrDayNumber span {
  color: #fff !important;
}

.rdrDateRangePickerWrapper {
  border: solid 1px #9a9cab !important;
  background-image: linear-gradient(195deg, #42424a, #191919) !important;
}

.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: #1f1f2b !important;
}

.rdrNextPrevButton,
.rdrDateDisplayItem,
.rdrDateDisplayItem input {
  background: #fff !important;
  color: #000 !important;
}

.rdrDateDisplayItemActive {
  border-color: #fff !important;
}

.rdrDateDisplayWrapper {
  background-color: #1f1f2b !important;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: #fff !important;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge,
.rdrStartEdge,
.rdrInRange,
.rdrEndEdge,
.rdrDayToday .rdrDayNumber span:after {
  background: #e91e63 !important;
}

.rdrInputRangeInput {
  /* border: solid 1px var(--bs-primary) !important; */
  color: #fff !important;
}

.rdrInputRangeInput:focus,
.rdrInputRangeInput:hover {
  border-color: var(--bs-primary);
  outline: 0;
  color: #fff !important;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  border-right-width: 1px;
  right: 0px;
  border-color: var(--bs-primary) !important;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 1.333em;
  border-bottom-left-radius: 1.333em;
  border-left-width: 1px;
  left: 0px;
  border-color: var(--bs-primary) !important;
}

.rdrDayEndPreview,
.rdrDayStartPreview {
  border-color: var(--bs-primary) !important;
}

.rdrDayInPreview {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: var(--bs-primary) !important;
}

.rdrYearPicker option,
.rdrMonthPicker option {
  background: #262635 !important;
}

.rdrYearPicker select::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 4px;
  /* width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}

.rdrYearPicker select::-webkit-scrollbar-track {
  background: #181821;
}

.rdrYearPicker select::-webkit-scrollbar-thumb {
  background: #262635;
}

.rdrDateDisplayItemActive {
  border: 3px solid #959595 !important;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.styles-module_Editext__input__2-M50,
.styles-module_Editext__button__sxYQX {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: #181821 !important;
  color: #fff !important;
  border-color: #181821 !important;
}

.hiddenRow {
  padding: 0 !important;
}

.profile-header .profile-img img {
  object-fit: cover;
}

.makeStyles-navLink-1.active {
  font-size: 15px !important;
}

.accordion-toggle {
  border-top-width: 3px;
}

/* .daterangepicker,
.daterangepicker .calendar-table,
.daterangepicker td.off {
  color: #9a9cab;
  background-color: #1f1f2b !important;
  border: 1px solid #1f1f2b !important;
}

.daterangepicker td.active,
.daterangepicker td.in-range,
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #181821 !important;
  border-color: transparent;
  color: #9a9cab !important;
}

.applyBtn {
  background-color: #ada6f2 !important;
}

.cancelBtn {
  color: #9a9cab !important;
}

.calendar-table .prev span,
.daterangepicker .calendar-table .next span {
  color: #1f1f2b !important;
  border: solid #9a9cab !important;

  border-width: 0 2px 2px 0 !important;
  border-radius: 0 !important;
  display: inline-block !important;
  padding: 3px !important;
}

.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  left: 50% !important;
}

.daterangepicker:after,
.daterangepicker:before {
  border-bottom: 6px solid #1f1f2b !important;
} */
.daterangepicker.opensright:before,
.daterangepicker.opensright:after {
  left: 75% !important;
}
label {
  font-weight: 18px;
}

.icon .react-icons {
  font-size: 40px;
  color: #fff;

  opacity: 0.8;
  position: relative;
  top: 12px;
}

.switch.s-icons.s-outline-primary {
  color: #4361ee;
}

.form-floating label {
  color: #959595 !important;
}

.form-floating label:focus {
  color: #007bff;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--bs-primary) !important;
}

/*
==================
    Switches
==================
*/

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
  display: none;
}

/* The slider */
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #888ea8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  background-color: white;
  -webkit-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded Slider Switches */
.switch .slider.round {
  border-radius: 34px;
}

.switch .slider.round:before {
  border-radius: 50%;
}

/* Solid Switches*/

.switch.s-default .slider:before {
  background-color: #d3d3d3;
}

.switch.s-primary .slider:before {
  background-color: #4361ee;
}

.switch.s-success .slider:before {
  background-color: #1abc9c;
}

.switch.s-warning .slider:before {
  background-color: #e2a03f;
}

.switch.s-danger .slider:before {
  background-color: #e7515a;
}

.switch.s-secondary .slider:before {
  background-color: #805dca;
}

.switch.s-info .slider:before {
  background-color: #2196f3;
}

.switch.s-dark .slider:before {
  background-color: #3b3f5c;
}

.switch input:checked + .slider:before {
  background-color: #fafafa;
}

.switch.s-default input:checked + .slider {
  background-color: #888ea8;
}

.switch.s-default input:focus + .slider {
  box-shadow: 0 0 1px #ebedf2;
}

.switch.s-primary input:checked + .slider {
  background-color: #4361ee;
}

.switch.s-primary input:focus + .slider {
  box-shadow: 0 0 1px #4361ee;
}

.switch.s-success input:checked + .slider {
  background-color: #1abc9c;
}

.switch.s-success input:focus + .slider {
  box-shadow: 0 0 1px #1abc9c;
}

.switch.s-warning input:checked + .slider {
  background-color: #e2a03f;
}

.switch.s-warning input:focus + .slider {
  box-shadow: 0 0 1px #e2a03f;
}

.switch.s-danger input:checked + .slider {
  background-color: #e7515a;
}

.switch.s-danger input:focus + .slider {
  box-shadow: 0 0 1px #e7515a;
}

.switch.s-secondary input:checked + .slider {
  background-color: #805dca;
}

.switch.s-secondary input:focus + .slider {
  box-shadow: 0 0 1px #805dca;
}

.switch.s-info input:checked + .slider {
  background-color: #2196f3;
}

.switch.s-info input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch.s-dark input:checked + .slider {
  background-color: #4361ee;
}

.switch.s-dark input:focus + .slider {
  box-shadow: 0 0 1px #4361ee;
}

/* Outline Switches */
.switch.s-outline .slider {
  border: 2px solid #a5aac6;
  background-color: transparent;
  width: 36px;
  height: 19px;
}

.switch.s-outline .slider:before {
  height: 13px;
  width: 13px;
}

.switch.s-outline[class*="s-outline-"] .slider:before {
  bottom: 1px;
  left: 1px;
  border: 2px solid #a5aac6;
  background-color: #a5aac6;
  color: #ebedf2;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.25);
}

.switch.s-icons.s-outline-default {
  color: #d3d3d3;
}

.switch.s-icons.s-outline-primary {
  color: #4361ee;
}

.switch.s-icons.s-outline-success {
  color: #1abc9c;
}

.switch.s-icons.s-outline-warning {
  color: #e2a03f;
}

.switch.s-icons.s-outline-danger {
  color: #e7515a;
}

.switch.s-icons.s-outline-secondary {
  color: #805dca;
}

.switch.s-icons.s-outline-info {
  color: #2196f3;
}

.switch.s-icons.s-outline-dark {
  color: #3b3f5c;
}

.switch.s-outline-default input:checked + .slider {
  border: 2px solid #888ea8;
}

.switch.s-outline-default input:checked + .slider:before {
  border: 2px solid #888ea8;
  background-color: #888ea8;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.25);
}

.switch.s-outline-default input:focus + .slider {
  box-shadow: 0 0 1px #ebedf2;
}

.switch.s-outline-primary input:checked + .slider {
  border: 2px solid var(--bs-info);
  background-color: transparent;
}

.switch.s-outline-primary input:checked + .slider:before {
  border: 2px solid var(--bs-info);
  background-color: var(--bs-info);
  box-shadow: 0 1px 15px 1px rgba(81, 40, 104, 0.34);
}

.switch.s-outline-primary input:focus + .slider {
  box-shadow: 0 0 1px #b693b1;
}

.switch.s-outline-success input:checked + .slider {
  border: 2px solid #1abc9c;
}

.switch.s-outline-success input:checked + .slider:before {
  border: 2px solid #1abc9c;
  background-color: #1abc9c;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-success input:focus + .slider {
  box-shadow: 0 0 1px #1abc9c;
}

.switch.s-outline-warning input:checked + .slider {
  border: 2px solid #e2a03f;
}

.switch.s-outline-warning input:checked + .slider:before {
  border: 2px solid #e2a03f;
  background-color: #e2a03f;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-warning input:focus + .slider {
  box-shadow: 0 0 1px #e2a03f;
}

.switch.s-outline-danger input:checked + .slider {
  border: 2px solid #e7515a;
}

.switch.s-outline-danger input:checked + .slider:before {
  border: 2px solid #e7515a;
  background-color: #e7515a;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-danger input:focus + .slider {
  box-shadow: 0 0 1px #e7515a;
}

.switch.s-outline-secondary input:checked + .slider {
  border: 2px solid #805dca;
}

.switch.s-outline-secondary input:checked + .slider:before {
  border: 2px solid #805dca;
  background-color: #805dca;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-secondary input:focus + .slider {
  box-shadow: 0 0 1px #805dca;
}

.switch.s-outline-info input:checked + .slider {
  border: 2px solid #2196f3;
}

.switch.s-outline-info input:checked + .slider:before {
  border: 2px solid #2196f3;
  background-color: #2196f3;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-info input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch.s-outline-dark input:checked + .slider {
  border: 2px solid #3b3f5c;
}

.switch.s-outline-dark input:checked + .slider:before {
  border: 2px solid #3b3f5c;
  background-color: #3b3f5c;
  box-shadow: 0 1px 15px 1px rgba(52, 40, 104, 0.34);
}

.switch.s-outline-dark input:focus + .slider {
  box-shadow: 0 0 1px #3b3f5c;
}

/*  Icons Switches */

.switch.s-icons {
  width: 57px;
  height: 30px;
}

.switch.s-icons .slider {
  width: 48px;
  height: 25px;
}

.switch.s-icons .slider:before {
  vertical-align: sub;
  color: #3b3f5c;
  height: 19px;
  width: 19px;
  line-height: 1.3;
  content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="%23e9ecef" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');
}

.switch.s-icons input:checked + .slider:before {
  content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="%23fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>');
  vertical-align: sub;
  color: #fff;
  line-height: 1.4;
}

.switch.s-icons input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}

.error-info h1 {
  font-size: 148px;
  color: rgb(223, 223, 138);
}

.user {
  padding: 0.5rem;
}

.userEdit {
  cursor: pointer;
  border-radius: 5px;
  color: #000;
  /* transform: translate3d(82px, 0px, 0px); */
  background: #e0e0e0;
}

.post-body,
.post-header {
  display: block;
  overflow: hidden;
  width: 100%;
}

.post-header img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  float: left;
}

.post-header .post-info {
  float: left;
  padding: 4px 15px 0 15px;
}

.post-header .post-info span.post-date {
  font-size: 13px;
  opacity: 0.6;
}

.post-header .post-header-actions {
  float: right;
  padding: 15px 0;
}

.post-header .post-header-actions a {
  line-height: 20px;
  color: #646464;
}

.post-header .post-header-actions a:hover {
  color: #333;
}

.post-body {
  padding: 15px 0 0 0;
  position: relative;
}

.post-body p {
  margin-bottom: 0;
}

.post-header .post-info span.post-author {
  font-size: 15px;
}

.post-body .post-image {
  display: block;
  width: 100%;
  border-radius: 6px;
  margin-top: 15px;
  max-height: 500px;
  object-fit: contain;
}

.post-actions,
.post-actions ul {
  display: block;
  overflow: hidden;
  margin-bottom: 0;
}

.post-actions {
  margin-top: 15px;
  padding: 5px 10px 0;
}

.post-actions ul li a {
  float: left;
  padding: 5px 8px;
  font-size: 17px;
  color: #9a9cab;
  margin-right: 10px;
}

.post-actions ul li a:hover {
  color: #fa4482;
}

.post-actions ul li a.liked {
  color: #844ff1;
}

.post-actions ul li a.liked i:before {
  content: "\f004";
}

.post-actions ul li a i {
  margin-right: 7px;
}

.post-comments {
  margin-top: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.post-comments .post-comm {
  display: block;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 15px;
  overflow: hidden;
}

.post-comments .post-comm:last-child {
  margin-bottom: 0;
}

.post-comments .post-comm img {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  float: left;
}

.post-comments .post-comm .comment-container {
  float: left;
  padding: 0 10px;
  width: calc(100% - 30px);
  display: block;
}

.post-comments .post-comm .comment-container span.comment-author {
  color: var(--bs-primary);
  display: block;
  line-height: 30px;
}

.post-comments .post-comm .comment-container span.comment-author small {
  float: right;
  font-size: 13px;
  color: #9a9cab;
  /* opacity: 0.6; */
}

.post-comments .post-comm .comment-text {
  padding: 10px 0 0 0;
  display: inline-block;
  margin-left: 40px;
}

.dash-drop {
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  will-change: box-shadow, transform;
  box-shadow: 0px 2px 4px rgb(45 35 66 / 40%),
    0px 7px 13px -3px rgb(45 35 66 / 30%),
    inset 0px -3px 0px rgb(58 65 111 / 50%);
  border-radius: 6px;
  font-size: 14px;
  text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
  width: 100px;
}

.user_profile_info {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  max-height: 0px;
  min-height: 0px;
  /* overflow: auto; */
}

.userProfilePost {
  background: #fff;
  border-radius: 10px;
  display: block;
  height: 200px;
  object-fit: fill;
  width: 200px;
  width: 100%;
}

.profile_box {
  width: 100%;
  height: 50px;
  text-align: center;
  padding-top: 10px;
  background-color: #eeeeee;
  border-radius: 10px;

  box-shadow: rgba(233, 220, 220, 0.24) 0px 3px 8px;
}

.cancelBtn {
  background-color: #357ebd;
  color: #fff;
}

.cancelBtn:hover {
  background-color: #357ebd;
  color: #fff;
}

.cancelBtn:focus {
  background-color: #357ebd;
  border-radius: none !important;
}
.applyBtn {
  background-color: var(--bs-primary);
  color: #fff;
}

.applyBtn:hover {
  background-color: var(--bs-primary);
  color: #fff;
}

.applyBtn:focus {
  background-color: var(--bs-primary);
  border-radius: none !important;
}

.rdrDayDisabled {
  cursor: no-drop !important;
  /* background-color: rgb(47, 44, 44) !important; */
  background-image: linear-gradient(195deg, #42424a, #191919) !important;
  color: #9a9cab !important;
}

.rdrDayDisabled .rdrDayNumber {
  text-decoration: line-through !important;
  text-decoration-color: #9a9cab !important;
  text-decoration-thickness: 2px !important;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #9a9cab !important;
}

body,
html {
  background: #f0f2f5 !important;
}

.follower_count {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.16);
  color: #1d0a87;
  font-weight: 700;
  padding: 9px 0;
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 768px) {
  .g-sidenav-pinned .navbar-vertical {
    max-width: 6rem !important;
  }
  .g-sidenav-pinned .navbar-vertical .navbar-brand img {
    width: auto !important;
  }
  .g-sidenav-pinned .navbar-vertical .navbar-brand span {
    opacity: 0;
  }

  .g-sidenav-pinned .navbar-vertical .nav-item .nav-link .icon {
    padding: 10px;
  }

  .g-sidenav-pinned .navbar-vertical .nav-item .nav-link .material-icons-round {
    margin-left: 2px;
  }
  .g-sidenav-pinned .navbar-vertical .nav-item .nav-link .nav-link-text,
  .g-sidenav-pinned .navbar-vertical .nav-item .nav-link .sidenav-normal {
    opacity: 0;
    width: 0;
  }

  .g-sidenav-pinned .navbar-vertical .nav-item .nav-link .sidenav-mini-icon {
    min-width: 1.8rem;
    margin-left: 0.15rem !important;
  }
}
.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* set the height to match the viewport height */
}

.slick-next {
  right: -10px !important;
}

.slick-prev {
  left: -10px !important;
}
.slick-prev:before {
  color: #000 !important;
}
.slick-next:before {
  color: #000 !important;
}

.apexcharts-toolbar {
  display: none !important;
}

.widget-chart-one .widget-heading {
  display: flex;
  justify-content: space-between;
}
.widget-chart-one #revenueMonthly {
  overflow: hidden;
}
.widget-chart-one .widget-content .apexcharts-canvas {
  transition: 0.5s;
}
.widget-chart-one .widget-content .apexcharts-canvas svg {
  transition: 0.5s;
}
.widget-chart-one .apexcharts-legend-marker {
  left: -5px !important;
}
.widget-chart-one .apexcharts-yaxis-title {
  font-weight: 600;
  fill: #bfc9d4;
}
.widget-chart-one .apexcharts-xaxis-title {
  font-weight: 600;
  fill: #bfc9d4;
}
.widget-chart-one .widget-heading .task-action svg {
  color: #888ea8;
  width: 19px;
  height: 19px;
}
.widget-chart-one .widget-heading .task-action .dropdown-menu {
  transform: translate3d(-141px, 0, 0px);
}

/*
  =======================
      Sold By cateory
  =======================
*/

.widget-chart-two {
  padding: 0;
}
.widget.widget-chart-two .widget-heading {
  padding: 20px 20px 0 20px;
}
.widget-chart-two .widget-heading .w-icon {
  position: absolute;
  right: 20px;
  top: 15px;
}
.widget-chart-two .widget-heading .w-icon a {
  padding: 6px;
  border-radius: 10px;
  padding: 6px;
  background: #3b3f5c !important;
  border: none;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.widget-chart-two .widget-heading .w-icon a svg {
  color: #fff;
}
.widget.widget-chart-two .widget-content {
  padding: 0 0 20px 0;
}
.widget-chart-two .apexcharts-canvas {
  margin: 0 auto;
}
.widget-chart-two .apexcharts-legend-marker {
  left: -5px !important;
}
[id*="apexcharts-donut-slice-"] {
  filter: none;
}
.fake-user-dialog .MuiInput-underline:before{
  border-bottom: none !important;
}
.fake-user-dialog .scroll-content{
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}















.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin: 0;
  padding: 0;
}

.sidebar a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #333;
}

.sidebar ul ul {
  display: none;
}

/* Show submenu when parent is hovered */
.sidebar li:hover > ul {
  display: block;
}